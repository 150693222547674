<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">JSON настроек</h2>
      </div>
    </div>

    <p class="primary--text" v-if="firebaseSettings.exists">JSON настроек загружен. Его содержание можете видеть ниже</p>
    <p class="error--text" v-else>JSON настроек сейчас не загружен</p>

    <pre>{{ firebaseSettings.settings }}</pre>

    <div class="mt-6">
      <p>Вы можете загрузить новый или обновить существующий файл настроек тут</p>
    </div>

    <b-field>
      <b-upload
        v-model="files"
        :multiple="false"
        drag-drop
        class="full-width-upload"
        accept=".json"
        @input="handleFileUpload"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon
                icon="upload"
                size="is-large"
              />
            </p>
            <p>Перетащите ваш JSON файл сюда или кликните для загрузки</p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <div class="tags">
      <span v-for="(file, index) in files"
            :key="index"
            class="tag is-primary">
                {{ file.name }}
        <button class="delete is-small"
                type="button"
                @click="deleteDropFile(index)">
        </button>
      </span>
    </div>

    <b-button
      label="Обновить"
      type="is-primary"
      @click="updateJsonSettings"
    />


    <OverlayLoader :show="isLoading"></OverlayLoader>

  </div>
</template>

<script>
import OverlayLoader from "@/app/shared/components/app-loader/Loader";

export default {
  name: "JsonSettings",
  components: {
    OverlayLoader,
  },
  data() {
    return {
      files: [],
    };
  },
  created() {
    this.getJsonSettings();
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters.IS_JSON_SETTINGS_LOADING;
      },
      set(value) {
        this.$store.commit("SET_JSON_SETTINGS_LOADING", value);
      },
    },
    idFirebase() {
      return this.$route.params.idFirebase;
    },
    firebaseSettings() {
      return this.$store.getters.GET_JSON_SETTINGS;
    }
  },
  methods: {
    handleFileUpload(file) {
      // Проверяем, что файл является JSON
      if (file && file.type === 'application/json') {
        this.files = [file];
      } else {
        this.$toast.error("Пожалуйста, выберите только JSON файл.");
      }
    },
    deleteDropFile(index) {
      this.files.splice(index, 1); // Удаляем файл из массива files
    },
    updateJsonSettings() {
      const formData = this.customToFormData({ files: this.files[0] });
      this.$store
        .dispatch("UPDATE_JSON_SETTINGS", {
          idFirebase: this.idFirebase,
          formData: formData,
        })
        .then(() => {
          this.$toast.success("JSON настройки обновлены");
          this.files = [];
        })
        .catch((error) => {
          this.$toast.error(error);
        }).finally(() => {
          this.getJsonSettings();
        });
    },
    getJsonSettings() {
      this.$store.dispatch("GET_JSON_SETTINGS", this.idFirebase)
        .catch(() => {
        this.$toast.error("Ошибка получения данных");
      });
    },

    customToFormData({ files }) {
      const formData = new FormData();
      if (files) {
        formData.append('files', files);
      }
      return formData;
    }
  },
};
</script>

<style>
.draggable-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
}
</style>
